body {
  margin: 0;
  font-family: "Cairo", sans-serif;
  background-color: #f0f0f0;
  direction: rtl;
  overflow: hidden;
}

.coming-soon {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: #333;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.header {
  width: 100%;
  background-color: #002750;
  padding: 20px 40px;
  display: flex;
  justify-content: end;
  align-items: center;
  box-sizing: border-box;
}

.logo {
  height: 50px;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
}

.coming-soon-image {
  max-width: 80%;
  max-height: 400px;
  height: auto;
  margin-bottom: 20px;
}

.coming-soon-text {
  font-size: 2em;
  color: #007bff;
}

.footer {
  width: 100%;
  background-color: #002750;
  color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.coming-soon-imageNew {
  max-width: 40%;
  max-height: 200px;
  height: auto;
  margin-bottom: 20px;
  border-radius: 12px;
}

.scroll-container {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.scroll-container img {
  flex: 0 0 auto;
}

@media (max-width: 600px) {
  .coming-soon .main div {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
  }

  .coming-soon-imageNew {
    max-width: 70%;
    margin-inline: 0;
  }
}

@media (max-width: 768px) {
  .coming-soon-text {
    font-size: 1.5em;
  }

  .logo {
    height: 40px;
  }

  .footer {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .coming-soon-text {
    font-size: 1.2em;
  }

  .logo {
    height: 30px;
  }

  .footer {
    font-size: 0.8em;
    margin: 65px;
  }
}
